var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('eden-page-header',{attrs:{"title":'Discount & Rewards',"subtitle":`Badges`,"section":_vm.badge.name || '-'}}),_c('eden-container',[_c('el-row',{attrs:{"type":"flex","align":"center"}},[_c('el-col',[_c('div',{staticClass:"badge"},[_c('div',{staticClass:"badge-image"},[(_vm.badge.badge_image_url)?_c('img',{style:(_vm.setBadgeStyle(_vm.badge.background_colour)),attrs:{"src":_vm.getThumbnailFromCloudinary(_vm.badge.badge_image_url),"alt":"Image"}}):_vm._e()]),_c('div',{staticClass:"badge-name"},[_c('p',[_vm._v(_vm._s(_vm.badge.name))]),_c('span',[_vm._v(_vm._s(_vm.badge.description))])])])]),_c('el-col',[_c('div',{staticClass:"badge"},[_c('div',{staticClass:"badge-metric-value"},[_c('p',[_vm._v(_vm._s(_vm.badge.owners_count))]),_c('span',[_vm._v("Users with this badge")])])])])],1)],1),_c('eden-table-actions',{attrs:{"title":_vm.title},on:{"search":_vm.search}},[(_vm.allowAccessFor(['admin', 'growth']))?_c('template',{slot:"actions"},[_c('badge-users-filter',{attrs:{"disabled":_vm.loading,"clear":_vm.filterParams.clear},on:{"filter":_vm.filter}})],1):_vm._e()],2),(_vm.loading)?_c('eden-loader'):[(_vm.filterParams.status)?_c('eden-filter-items',{attrs:{"params":_vm.filterParams.paramsLabel},on:{"clear-filter":_vm.clearFilter,"clear-filters":_vm.clearFilters}}):_vm._e(),(_vm.pageData.length)?[_c('el-table',{attrs:{"data":_vm.pageData}},[_c('el-table-column',{attrs:{"width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
                name: 'customers.individual',
                params: { id: scope.row.id },
              }}},[_c('span',{staticClass:"font-sm text-primary"},[_vm._v(" "+_vm._s(_vm.formatName(scope.row.name)))])]),_c('span',{staticClass:"font-xsm"},[_vm._v(" "+_vm._s(scope.row.email)+" ")])]}}],null,false,2758272264)},[_c('template',{slot:"header"},[_c('eden-table-column-header',{attrs:{"label":'Name',"property":'name',"sort-property":_vm.sort.property},on:{"sort":function($event){return _vm.sortPageData($event, 'name')}}})],1)],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.badges_count))])]}}],null,false,2707602348)},[_c('template',{slot:"header"},[_c('eden-table-column-header',{attrs:{"label":'Total Badges Unlocked',"property":'total-badges-unlocked',"sort-property":_vm.sort.property},on:{"sort":function($event){return _vm.sortPageData($event, 'badges_count')}}})],1)],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(scope.row.created_at, "mm d, y")))])]}}],null,false,1506937831)},[_c('template',{slot:"header"},[_c('eden-table-column-header',{attrs:{"label":'Date Unlocked',"property":'date-unlocked',"sort-property":_vm.sort.property},on:{"sort":function($event){return _vm.sortPageData($event, 'created_at')}}})],1)],2),_c('el-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"font-sm table--status"},[_c('el-tag',{attrs:{"type":_vm.setType(scope.row.customer_status)}},[_vm._v(" "+_vm._s(_vm.formatText(scope.row.customer_status))+" ")])],1)]}}],null,false,3053264550)},[_c('template',{slot:"header"},[_c('eden-table-column-header',{attrs:{"label":'Status',"property":'status',"sort-property":_vm.sort.property},on:{"sort":function($event){return _vm.sortPageData($event, 'customer_status')}}})],1)],2),_c('el-table-column',{attrs:{"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{staticClass:"rewards",attrs:{"to":{
                name: 'customers.individual.rewards',
                params: { id: scope.row.id },
              }}},[_vm._v("Open rewards profile")])]}}],null,false,1150645720)})],1),(_vm.showPagination)?_c('eden-pagination',{attrs:{"from":_vm.pagination.from,"to":_vm.pagination.to,"total":_vm.pagination.total,"current-page":_vm.page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}}):_vm._e()]:_c('eden-content-empty',{attrs:{"text":'No Garden Users'}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }