<template>
  <div>
    <eden-page-header
      :title="'Discount & Rewards'"
      :subtitle="`Badges`"
      :section="badge.name || '-'"
    />
    <eden-container>
      <el-row type="flex" align="center">
        <el-col>
          <div class="badge">
            <div class="badge-image">
              <img
                v-if="badge.badge_image_url"
                :src="getThumbnailFromCloudinary(badge.badge_image_url)"
                :style="setBadgeStyle(badge.background_colour)"
                alt="Image"
              />
            </div>
            <div class="badge-name">
              <p>{{ badge.name }}</p>
              <span>{{ badge.description }}</span>
            </div>
          </div>
        </el-col>
        <el-col>
          <div class="badge">
            <div class="badge-metric-value">
              <p>{{ badge.owners_count }}</p>
              <span>Users with this badge</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </eden-container>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions" v-if="allowAccessFor(['admin', 'growth'])">
        <badge-users-filter
          :disabled="loading"
          :clear="filterParams.clear"
          @filter="filter"
        />
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" />
    <template v-else>
      <eden-filter-items
        v-if="filterParams.status"
        :params="filterParams.paramsLabel"
        @clear-filter="clearFilter"
        @clear-filters="clearFilters"
      />
      <template v-if="pageData.length">
        <el-table :data="pageData">
          <el-table-column width="200">
            <template slot="header">
              <eden-table-column-header
                :label="'Name'"
                :property="'name'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'name')"
              />
            </template>
            <template v-slot="scope">
              <router-link
                :to="{
                  name: 'customers.individual',
                  params: { id: scope.row.id },
                }"
              >
                <span class="font-sm text-primary">
                  {{ formatName(scope.row.name) }}</span
                ></router-link
              >
              <span class="font-xsm"> {{ scope.row.email }} </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <eden-table-column-header
                :label="'Total Badges Unlocked'"
                :property="'total-badges-unlocked'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'badges_count')"
              />
            </template>
            <template v-slot="scope">
              <span>{{ scope.row.badges_count }}</span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <eden-table-column-header
                :label="'Date Unlocked'"
                :property="'date-unlocked'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'created_at')"
              />
            </template>
            <template v-slot="scope">
              <span>{{ formatDate(scope.row.created_at, "mm d, y") }}</span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <eden-table-column-header
                :label="'Status'"
                :property="'status'"
                :sort-property="sort.property"
                @sort="sortPageData($event, 'customer_status')"
              />
            </template>
            <template v-slot="scope">
              <span class="font-sm table--status">
                <el-tag :type="setType(scope.row.customer_status)">
                  {{ formatText(scope.row.customer_status) }}
                </el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column width="180">
            <template v-slot="scope">
              <router-link
                class="rewards"
                :to="{
                  name: 'customers.individual.rewards',
                  params: { id: scope.row.id },
                }"
                >Open rewards profile</router-link
              ></template
            >
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination"
          :from="pagination.from"
          :to="pagination.to"
          :total="pagination.total"
          :current-page.sync="page"
        />
      </template>
      <eden-content-empty v-else :text="'No Garden Users'" />
    </template>
  </div>
</template>

<script>
import BadgeUsersFilter from "./../../components/DiscountsAndRewards/Badges/BadgeUsersFilter";
import badges from "@/requests/discounts/badges";
import {
  mapBadgeDataFromBackend,
  mapBadgeOwnersDataFromBackend,
} from "@/helpers/api-mappers/badges";

export default {
  name: "Badge",
  components: {
    BadgeUsersFilter,
  },
  data() {
    return {
      loading: false,
      showPagination: true,
      page: 1,
      pagination: {
        from: null,
        to: null,
        total: null,
      },
      pageData: [],
      badge: {},
      sort: {
        property: "name",
        direction: "asc",
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
    };
  },
  computed: {
    badgeId() {
      return this.$route.params.id;
    },
    title() {
      const total = this.badge?.owners_count;
      const result = this.pageData.length;
      return `${
        (this.showPagination ? total : result) || 0
      } Users With This Badge`;
    },
  },
  created() {
    this.getBadge();
  },
  watch: {
    page() {
      this.getBadge();
    },
  },
  methods: {
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.pageData = this.sortList(this.pageData, property, direction);
    },
    setBadgeStyle(color) {
      return {
        border: color ? `5px solid ${color}` : "none",
      };
    },
    getBadge() {
      this.loading = true;
      const params = `page=${this.page}`;

      badges
        .get(this.badgeId, params)
        .then((response) => {
          console.log(response.data, "eerr");
          const { status, data } = response.data;

          if (status) {
            this.badge = mapBadgeDataFromBackend(data.badge);
            this.pagination.from = data.owners.from;
            this.pagination.to = data.owners.to;
            this.pagination.total = data.owners.total;
            this.pageData = data.owners.data.map((item) =>
              mapBadgeOwnersDataFromBackend(item),
            );
            this.showPagination = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.showPagination = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search(query) {
      if (query === "") {
        this.getBadge();
        return;
      }
      this.loading = true;
      const params = `query=${query}`;

      badges
        .get(this.badgeId, params)
        .then((response) => {
          console.log(response.data, "eerr");
          const { status, data } = response.data;

          if (status) {
            this.badge = mapBadgeDataFromBackend(data.badge);
            this.pagination.from = data.owners.from;
            this.pagination.to = data.owners.to;
            this.pagination.total = data.owners.total;
            this.pageData = data.owners.data.map((item) =>
              mapBadgeOwnersDataFromBackend(item),
            );
            this.showPagination = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.showPagination = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filter({ params, paramsLabel }) {
      console.log(params, paramsLabel);

      const stringedParams = this.sortObjectAsParams(params);
      this.loading = true;

      badges
        .get(this.badgeId, stringedParams)
        .then((response) => {
          const { status, data } = response.data;

          if (status) {
            this.filterParams = {
              status: true,
              params: params,
              paramsLabel: paramsLabel,
            };

            this.pagination.from = data.owners.from;
            this.pagination.to = data.owners.to;
            this.pagination.total = data.owners.total;
            this.pageData = data.owners.data.map((item) =>
              mapBadgeOwnersDataFromBackend(item),
            );
            this.showPagination = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;

      if (Object.keys(keys).length) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.getBadge();
      }
    },
    clearFilters() {
      this.getBadge();

      this.filterParams = {
        clear: true,
        status: false,
        params: {},
        paramsLabel: {},
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.rewards {
  display: inline-block;
  margin: 5px 0;
  font-size: 0.775rem;
  color: var(--eden-green-primary);
  border-bottom: 1px solid var(--eden-green-primary);
}

.badge {
  display: flex;
  flex-direction: column;

  &-image {
    height: 50px;
    width: 50px;
    min-height: 50px;
    min-width: 50px;
    border-radius: 100%;
    background: var(--eden-grey-tertiary);
    margin-right: 20px;
    margin-bottom: 10px;

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 100%;
      background: var(--eden-grey-tertiary);
    }
  }

  &-name {
    p {
      font-weight: 500;
      font-size: 0.9rem;
      margin-bottom: 1px;
    }

    span {
      color: var(--eden-grey-tertiary);
      font-size: 0.8rem;
    }
  }

  &-metric-value {
    text-align: right;

    p {
      font-weight: 500;
      font-size: 2rem;
      margin-bottom: 1px;
    }

    span {
      color: var(--eden-grey-tertiary);
      font-size: 0.8rem;
    }
  }
}
</style>
