export const mapBadgeDataFromBackend = (data) => ({
  background_colour: data.background_colour,
  badge_image_url: data.badge_image_url,
  description: data.description,
  expiry_date: data.expiry_date,
  has_validity_period: data.has_validity_period,
  id: data.id,
  name: data.name,
  owners_count: data.owners_count,
  pending_status: data.pending_status,
  validity_start_date: data.validity_start_date,
});

export const mapBadgeOwnersDataFromBackend = (data) => ({
  id: data.owner?.id,
  name: data.owner?.name,
  customer_status: data.owner?.customer_status,
  email: data.owner?.email,
  badges_count: data.owner?.badges_count,
  created_at: data?.created_at,
});
