import axios from "axios";

export default {
  index() {
    return axios.get("rewards_badges");
  },

  list() {
    return axios.get("rewards_badges");
  },

  search(query) {
    return axios.get(`rewards_badges?query=${query}`);
  },

  add(payload) {
    return axios.post("rewards_badges", payload);
  },

  update(id, payload) {
    return axios.put(`rewards_badges/${id}`, payload);
  },

  delete(id) {
    return axios.post(`rewards_badges/${id}/delete`);
  },

  get(id, params = "") {
    return axios.get(`rewards_badges/${id}?${params}`);
  },
};
